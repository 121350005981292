import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ethers } from "ethers";
import blockchainPriceFeeds from "../utils/priceFeedsByChainId";
import feedPriceAbi from "../utils/abis/priceFeedAbi.json";
import {
  setNormalizedPrice,
  setPrices,
  setAllPrices,
} from "../reducers/pricesSlice";

import { getFallbackProvider } from "../utils/getFallbackProvider";

const priceFeedAbi = feedPriceAbi;

const fallbackProvider = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_FALLBACK_PROVIDER
);

export const useBlockchain = ({
  isConnected,
  chainId,
  walletProvider,
  selectedCurrency,
  selectedFiat,
  buyWithCard,
}) => {
  const dispatch = useDispatch();

  const normalizedPrice = useSelector((state) => state.prices.normalizedPrice);
  const prices = useSelector((state) => state.prices.prices);
  const allPrices = useSelector((state) => state.prices.allPrices);

  const fetchNormalizedPrice = useCallback(async () => {
    try {
      const provider =
        isConnected && !buyWithCard
          ? new ethers.providers.Web3Provider(walletProvider)
          : fallbackProvider;

      let signerOrProvider;
      if (isConnected && !buyWithCard) {
        if (!walletProvider) {
          throw new Error("Wallet provider is not connected or initialized");
        }
        signerOrProvider = provider.getSigner();
      } else {
        signerOrProvider = provider;
      }

      const effectiveChainId = isConnected && !buyWithCard ? chainId : "137";

      if (
        (["USDT", "USDC", "USDCE", "DAI"].includes(selectedCurrency) &&
          !buyWithCard) ||
        (buyWithCard && selectedFiat === "USD")
      ) {
        dispatch(setNormalizedPrice(1));
      } else {
        const selectedToken = buyWithCard ? selectedFiat : selectedCurrency;
        const tokenData =
          blockchainPriceFeeds[effectiveChainId]?.[selectedToken];

        if (!tokenData) {
          return;
        }

        const PriceFeedContract = new ethers.Contract(
          tokenData.address,
          priceFeedAbi,
          signerOrProvider
        );

        const { answer } = await PriceFeedContract.latestRoundData();

        dispatch(setNormalizedPrice(parseFloat(answer) / 10 ** 8));
      }
    } catch (error) {
      return;
    }
  }, [
    isConnected,
    chainId,
    walletProvider,
    selectedCurrency,
    selectedFiat,
    buyWithCard,
    dispatch,
  ]);

  const fetchPriceForCurrency = useCallback(
    async (currency, chainId) => {
      try {
        const provider =
          isConnected && !buyWithCard
            ? new ethers.providers.Web3Provider(walletProvider)
            : fallbackProvider;

        let signerOrProvider;
        if (isConnected && !buyWithCard) {
          if (!walletProvider) {
            throw new Error("Wallet provider is not connected or initialized");
          }
          signerOrProvider = provider.getSigner();
        } else {
          signerOrProvider = provider;
        }

        const effectiveChainId =
          chainId || (isConnected && !buyWithCard ? chainId : "137");

        if (
          (["USDT", "USDC", "USDCE", "DAI"].includes(currency) &&
            !buyWithCard) ||
          (buyWithCard && selectedFiat === "USD")
        ) {
          return 1;
        } else {
          const tokenData = blockchainPriceFeeds[effectiveChainId]?.[currency];

          if (!tokenData) {
            throw new Error(
              "Token data not found for the selected currency and chain."
            );
          }

          const PriceFeedContract = new ethers.Contract(
            tokenData.address,
            priceFeedAbi,
            signerOrProvider
          );

          const { answer } = await PriceFeedContract.latestRoundData();
          return parseFloat(answer) / 10 ** 8;
        }
      } catch (error) {
        return null;
      }
    },
    [isConnected, chainId, walletProvider, selectedFiat, buyWithCard]
  );

  const fetchAllPrices = useCallback(async () => {
    const effectiveChainId = isConnected && !buyWithCard ? chainId : "137";
    const currencies = Object.keys(
      blockchainPriceFeeds[effectiveChainId] || {}
    );
    const prices = {};

    for (const currency of currencies) {
      const price = await fetchPriceForCurrency(currency, effectiveChainId);
      if (price !== null) {
        prices[currency] = price;
      }
    }

    dispatch(setPrices(prices));
  }, [isConnected, chainId, fetchPriceForCurrency, buyWithCard, dispatch]);

  const fetchAllPricesFor137 = useCallback(async () => {
    const currencies = Object.keys(blockchainPriceFeeds["137"] || {});
    const allPrices = {};

    for (const currency of currencies) {
      const price = await fetchPriceForCurrency(currency, "137");
      if (price !== null) {
        allPrices[currency] = price;
      }
    }

    dispatch(setAllPrices(allPrices));
  }, [fetchPriceForCurrency, dispatch]);

  useEffect(() => {
    fetchNormalizedPrice();
  }, [
    isConnected,
    chainId,
    walletProvider,
    selectedCurrency,
    selectedFiat,
    buyWithCard,
    fetchNormalizedPrice,
  ]);

  // useEffect(() => {
  //   fetchAllPrices();
  //   fetchAllPricesFor137();
  // }, [isConnected, chainId, fetchAllPrices, fetchAllPricesFor137]);

  return {
    normalizedPrice,
    prices,
    allPrices,
    fetchNormalizedPrice,
    fetchAllPrices,
    fetchAllPricesFor137,
    fetchPriceForCurrency,
  };
};
