// src/hooks/useReferrer.js

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import {
  setReferrer,
  setReferrerAddress,
  incrementReferrerUpdate,
} from "../reducers/referrerSlice";
import { ethers } from "ethers";

const useReferrer = () => {
  const ZERO_ADDRESS = ethers.constants.AddressZero;
  const dispatch = useDispatch();
  const { address, isConnected } = useWeb3ModalAccount();
  const referrer = useSelector((state) => state.referrer.referrer);
  const user = useSelector((state) => state.user);
  const [isReferrerLoaded, setIsReferrerLoaded] = useState(false);
  const authReady = useSelector((state) => state.auth.authReady);

  // Helper function for exponential backoff retries
  const fetchWithRetry = useCallback(async (fn, retries = 5, delay = 500) => {
    try {
      return await fn();
    } catch (error) {
      if (retries === 0) throw error;
      await new Promise((resolve) => setTimeout(resolve, delay));
      return fetchWithRetry(fn, retries - 1, delay * 2);
    }
  }, []);

  // Function to fetch the referrer address
  const getReferrerAddress = useCallback(
    async (referrerUrl) => {
      if (!referrerUrl) {
        return ZERO_ADDRESS;
      }

      try {
        const response = await fetchWithRetry(
          () =>
            fetch(`${process.env.REACT_APP_SERVER_URL}/getReferrerAddress`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ referrerUrl }),
            }),
          5, // Number of retries
          500 // Initial delay in ms
        );

        if (response.ok) {
          const data = await response.json();
          return data.referrerAddress || ZERO_ADDRESS;
        } else {
          // Handle non-OK responses if necessary
          return ZERO_ADDRESS;
        }
      } catch (error) {
        console.error(
          "getReferrerAddress: Error fetching referrer address:",
          error
        );
        return ZERO_ADDRESS;
      }
    },
    [fetchWithRetry]
  );

  // Function to fetch referral link data
  const getReferralLinkData = useCallback(async (userAddress) => {
    if (!userAddress) {
      console.error("getReferralLinkData: userAddress is empty");
      return null;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getReferralLinkData`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ address: userAddress }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (!data.userFound) {
          // console.log("getReferralLinkData: User not found.");
          return null;
        }
        return data.referralLink;
      } else {
        // Handle non-OK responses if necessary
        return null;
      }
    } catch (error) {
      console.error(
        "getReferralLinkData: Error fetching ReferralLink data:",
        error
      );
      return null;
    }
  }, []);

  // First useEffect to capture referrer from URL or localStorage
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrerParam = urlParams.get("ref");
    if (referrerParam) {
      dispatch(setReferrer(referrerParam));
    } else {
      // Retrieve referrer from localStorage if available
      const storedReferrer = localStorage.getItem("referrer");
      if (storedReferrer) {
        dispatch(setReferrer(storedReferrer));
      }
    }
  }, [dispatch, address]);

  // Second useEffect to handle referrer logic when connected
  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates on unmounted component

    const checkBackendReferrer = async () => {
      if (!isConnected || !address || !user || !user?.walletAddress) {
        // Ensure that address and connection status are available
        return;
      }

      try {
        const referralLink = await getReferralLinkData(address);
        if (referralLink) {
          const backendReferrerId = referralLink.referrer_id;
          if (backendReferrerId !== undefined) {
            dispatch(setReferrer(backendReferrerId));
            if (backendReferrerId === null) {
              dispatch(incrementReferrerUpdate());
            }

            const referrerAddress = await getReferrerAddress(backendReferrerId);

            if (referrerAddress !== ZERO_ADDRESS) {
              if (isMounted) {
                dispatch(setReferrerAddress(referrerAddress));
              }
            } else {
              dispatch(setReferrerAddress(ZERO_ADDRESS));
            }
          } else if (referrer) {
            const referrerAddress = await getReferrerAddress(referrer);

            if (referrer === null) {
              dispatch(incrementReferrerUpdate());
            }

            if (referrerAddress !== ZERO_ADDRESS) {
              if (isMounted) {
                dispatch(setReferrerAddress(referrerAddress));
              }
            } else {
              console.error(
                "Failed to fetch referrer address from existing referrer"
              );
              if (isMounted) {
                dispatch(setReferrerAddress(ZERO_ADDRESS));
              }
            }
          } else {
            if (isMounted) {
              dispatch(setReferrerAddress(ZERO_ADDRESS));
            }
          }
        } else if (referrer) {
          const referrerAddress = await getReferrerAddress(referrer);

          if (referrerAddress !== ZERO_ADDRESS) {
            if (isMounted) {
              dispatch(setReferrerAddress(referrerAddress));
            }
          } else {
            console.error(
              "Failed to fetch referrer address from existing referrer"
            );
            if (isMounted) {
              dispatch(setReferrerAddress(ZERO_ADDRESS));
            }
          }
          if (referrer === null) {
            dispatch(incrementReferrerUpdate());
          }
        } else {
          if (isMounted) {
            dispatch(setReferrerAddress(ZERO_ADDRESS));
            dispatch(incrementReferrerUpdate());
          }
        }
      } catch (error) {
        console.error("Error in checkBackendReferrer:", error);
        if (isMounted) {
          dispatch(setReferrerAddress(ZERO_ADDRESS));
          dispatch(incrementReferrerUpdate());
        }
      } finally {
        if (isMounted) {
          setIsReferrerLoaded(true);
        }
      }
    };

    checkBackendReferrer();

    return () => {
      isMounted = false;
    };
  }, [
    dispatch,
    isConnected,
    address,
    user,
    getReferralLinkData,
    getReferrerAddress,
    referrer,
  ]);

  return { referrer, isReferrerLoaded };
};

export default useReferrer;
