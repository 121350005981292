import diamond from "../../assets/img/diamond.png";
import snovaCoin from "../../assets/img/snova-coin-white.svg";
import buySnova from "../../assets/img/buy-snova-coins.png";
import currentAPR from "../../assets/img/current-snova-apr.png";
import totalStaked from "../../assets/img/total-snova-staked.png";
import yourHoldings from "../../assets/img/your-snova-holdings.png";
import unstakedBalance from "../../assets/img/your-unstaked-balance.png";
import totalRewards from "../../assets/img/accrued-rewards-info.png";
import deposit from "../../assets/img/deposit-snova-staking.png";
import purchaseSuccess from "../../assets/img/purchase-successful.webp";
import claimSuccess from "../../assets/img/claim-successful.png";
import done from "../../assets/img/done.svg";
import referralCoins from "../../assets/img/referral-coins.svg";
import CopyIco from "../../assets/img/copy.svg";
import purchaseSummary from "../../assets/img/purchase-summary-icon.svg";
import novaPoints from "../../assets/img/nova-points.svg";
import withdraw from "../../assets/img/withdraw-snova-staking.png";
import modalConfirmation from "../../assets/img/modal-title-confirmation.png";
import LoaderIcon from "../../assets/img/icon.webp";
import folderOpen from "../../assets/img/folder-open.svg";
import rocketIcon from "../../assets/img/rocket-icon.svg";
import { ExclamationIcon } from "../Home/Banner/Icons/ExclamationIcon";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import "./Banner.css";
import CircleInfo from "../../assets/img/circle-info.svg";
// import { Tooltip } from "react-tooltip";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useDispatch, useSelector } from "react-redux";
import { setBlur3 } from "../../reducers/blurSlice";
import formatReferralLink from "../../utils/formatReferralLink";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../utils/helpers";
import { signAndVerifyIfNeeded } from "../../utils/verificationUtils";
import ReactDOM from "react-dom";
import ModalPortal from "../ModalPortal";

const { ethers } = require("ethers");

// Custom Tooltip Component (similar to ConnectButton.js)
const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div style={{ position: "relative", cursor: "pointer" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
      >
        {children}
      </div>
      {showTooltip && (
        <div style={{ width: "max-content" }} className="main-tp to-os">
          <div />
          {text}
        </div>
      )}
    </div>
  );
};

// Custom Tooltip Component (similar to ConnectButton.js)
const TooltipButton = React.memo(({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div style={{ position: "relative", display: "block" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
        style={{ width: "100%" }}
      >
        {children}
      </div>
      {showTooltip && (
        <div className="main-tp" style={{ width: "100%" }}>
          <div />
          {text}
        </div>
      )}
    </div>
  );
});

const Banner = () => {
  const [modalAction, setModalAction] = useState(null);

  // We store these so we can position the modal absolutely in the document.
  const [modalTop, setModalTop] = useState(0);

  // Translation i18.js
  const { t } = useTranslation();
  const [isStake, setIsStake] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [stakingSuccess, setstakingSuccess] = useState(false);
  const [unStakingSummary, setunStakingSummary] = useState(false);
  const [compoundSummary, setcompoundSummary] = useState(false);
  const [claimSummary, setclaimSummary] = useState(false);
  const [previouslyClaimedAmount, setPreviousClaimedAmount] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [stakingData, setStakingData] = useState(null);

  const [isDesk, setIsDesk] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesk(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [tvlData, setTvlData] = useState({
    totalValueLocked: 0,
    totalValueLockedUsd: 0,
  });

  const [amount, setAmount] = useState("");
  const [rawAmount, setRawAmount] = useState("");
  const [loading, setLoading] = useState(true);

  const [inputValue, setInputValue] = useState("0.0");
  const [sliderValue, setSliderValue] = useState(0);

  const sliderTrackRef = useRef(null);
  const thumbRef = useRef(null);

  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const dispatch = useDispatch();
  const isBlurred3 = useSelector((state) => state.blur.isBlurred3);
  const referralLink = useSelector((state) => state.referral.referral);

  const [isThumbHovered, setIsThumbHovered] = useState(false);
  const [isThumbFocused, setIsThumbFocused] = useState(false);

  const [isStakingPaused, setIsStakingPaused] = useState(false);
  const minimumStakeUSD = 10;

  const faqData = [
    {
      question: t("Staking.banner.faqQuestion1"),
      answer: t("Staking.banner.faqAnswer1"),
    },
    {
      question: t("Staking.banner.faqQuestion2"),
      answer: t("Staking.banner.faqAnswer2"),
    },
    {
      question: t("Staking.banner.faqQuestion3"),
      answer: t("Staking.banner.faqAnswer3"),
    },
    {
      question: t("Staking.banner.faqQuestion4"),
      answer: t("Staking.banner.faqAnswer4"),
    },
    {
      question: t("Staking.banner.faqQuestion5"),
      answer: t("Staking.banner.faqAnswer5"),
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const fetchStakingData = async () => {
    if (isConnected && address) {
      try {
        const csrfToken = getCookie("csrfToken");

        const response = await fetch("/.netlify/functions/getStakingData", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken || "",
          },
          body: JSON.stringify({ walletAddress: address }),
          credentials: "include",
        });
        const data = await response.json();
        setStakingData(data);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch staking data:", err);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchTVLData = async () => {
    try {
      const response = await fetch("/.netlify/functions/getTVL", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      setTvlData(data);
    } catch (err) {
      console.error("Failed to fetch TVL data:", err);
    }
  };

  useEffect(() => {
    fetchTVLData();
  }, []);

  useEffect(() => {
    if (isConnected && address) {
      setLoading(true);
      fetchStakingData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [isConnected, address]);

  useEffect(() => {
    setAmount(inputValue);
  }, [inputValue]);

  const StakeIt = () => {
    setIsStake(true);
    resetInputAndSlider();
  };

  const unStakeIt = () => {
    setIsStake(false);
    resetInputAndSlider();
  };

  const fmt = (num, decimals) => {
    if (typeof num === "number") return num.toFixed(decimals);
    const parsed = parseFloat(num);
    return isNaN(parsed) ? "0.0000" : parsed.toFixed(decimals);
  };

  const {
    totalSnovaHoldings = 0,
    stakedSnovaBalance = 0,
    unstakedSnova = 0,
    claimableRewards = 0,
    claimableRewardsValue = 0,
    totalEarnings = 0,
    totalEarningsValue = 0,
    stakingTransactions = [],
    price = 0,
    nextAvailableActionTimestamp = null,
  } = stakingData || {};

  const { totalValueLocked = 0 } = tvlData;

  const formatDate = (d) => {
    const dateObj = new Date(d);
    return dateObj.toUTCString();
  };

  const getMaxAmount = () => {
    return isStake ? unstakedSnova : stakedSnovaBalance;
  };

  const resetInputAndSlider = () => {
    setInputValue("0.0");
    setRawAmount("0.0");
    setSliderValue(0);
  };

  const handleMax = () => {
    const maxAmount = getMaxAmount();
    setInputValue(fmt(maxAmount, 4));
    setRawAmount(maxAmount);
    setSliderValue(100);
  };

  const handleHalf = () => {
    const maxAmount = getMaxAmount();
    const half = maxAmount / 2;
    setInputValue(fmt(half, 4));
    setRawAmount(half);
    setSliderValue(50);
  };

  const handleInputChange = (e) => {
    if (disableSlider) return;
    const val = e.target.value;
    if (val === "") {
      setInputValue(val);
      setRawAmount(val);
      setSliderValue(0);
      return;
    }
    const parsed = parseFloat(val);
    if (!isNaN(parsed) && parsed >= 0) {
      setInputValue(val);
      setRawAmount(val);
      const maxAmount = getMaxAmount();
      if (maxAmount > 0) {
        const percent = Math.min((parsed / maxAmount) * 100, 100);
        setSliderValue(percent);
      } else {
        setSliderValue(0);
      }
    }
  };

  const disableSlider = !isConnected || getMaxAmount() <= 0;

  let isDragging = false;

  const onMouseMove = (e) => {
    if (!isDragging || !sliderTrackRef.current || disableSlider) return;
    const rect = sliderTrackRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    let newPercent = (x / width) * 100;
    if (newPercent < 0) newPercent = 0;
    if (newPercent > 100) newPercent = 100;
    setSliderValue(newPercent);
    const maxAmount = getMaxAmount();
    const valAmount = (maxAmount * newPercent) / 100;
    setInputValue(fmt(valAmount, 4));
    setRawAmount(valAmount);
  };

  const onMouseUp = () => {
    if (isDragging) {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      isDragging = false;
    }
  };

  const onTrackMouseDown = (e) => {
    if (disableSlider) return;
    e.preventDefault();
    isDragging = true;
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    onMouseMove(e);
  };

  const onThumbMouseDown = (e) => {
    if (disableSlider) return;
    e.preventDefault();
    isDragging = true;
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const handleThumbMouseEnter = () => {
    if (!disableSlider && isConnected && getMaxAmount() > 0) {
      setIsThumbHovered(true);
    }
  };

  const handleThumbMouseLeave = () => {
    setIsThumbHovered(false);
  };

  const handleThumbFocus = () => {
    if (!disableSlider && isConnected && getMaxAmount() > 0) {
      setIsThumbFocused(true);
    }
  };

  const handleThumbBlur = () => {
    setIsThumbFocused(false);
  };

  const handleAction = (action) => {
    if (action === "Stake" || action === "Unstake") {
      dispatch(setBlur3(true));
    }
    setModalAction(action);
  };

  const closeModal = () => {
    setModalAction(null);
    setAmount("");
    dispatch(setBlur3(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setModalAction(null);

    if (
      (modalAction === "Stake" || modalAction === "Unstake") &&
      (!rawAmount || isNaN(rawAmount) || Number(rawAmount) <= 0)
    ) {
      return;
    }

    if (!address) {
      return;
    }

    try {
      setLoading(true);

      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      await signAndVerifyIfNeeded(address, ethersProvider);

      let endpoint = "";
      let data = { walletAddress: address };

      switch (modalAction) {
        case "Stake":
          endpoint = "/.netlify/functions/stake";
          data.amount = Number(rawAmount);
          break;
        case "Unstake":
          endpoint = "/.netlify/functions/unstake";
          data.amount = Number(rawAmount);
          break;
        case "Claim":
          endpoint = "/.netlify/functions/claimRewards";
          break;
        case "Compound":
          endpoint = "/.netlify/functions/compoundRewards";
          break;
        default:
          break;
      }

      const csrfToken = getCookie("csrfToken");
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken || "",
        },
        body: JSON.stringify(data),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP Error ${response.status}`);
      }

      const responseData = await response.json();
      setPreviousClaimedAmount(claimableRewards);
      // Refresh staking data
      await fetchStakingData();
      switch (modalAction) {
        case "Stake":
          setstakingSuccess(true);
          break;
        case "Unstake":
          setunStakingSummary(true);
          break;
        case "Claim":
          setclaimSummary(true);
          break;
        case "Compound":
          setcompoundSummary(true);
          break;
        default:
          break;
      }

      setAmount("");
      dispatch(setBlur3(false));
      setLoading(false);
    } catch (error) {
      console.error(`Error performing ${modalAction}:`, error);
      dispatch(setBlur3(false));
      setLoading(false);
    }
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((error) => console.error("Error copying text: ", error));
  };
  let setAllModal = () => {
    setstakingSuccess(false);
    setcompoundSummary(false);
    setunStakingSummary(false);
    setclaimSummary(false);
  };
  // CLICK ON TRACK
  const handleTrackClick = (e) => {
    if (!sliderTrackRef.current) return;
    if (thumbRef.current && thumbRef.current.contains(e.target)) {
      return;
    }
    const rect = sliderTrackRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    let newPercent = (x / width) * 100;
    if (newPercent < 0) newPercent = 0;
    if (newPercent > 100) newPercent = 100;
    setSliderValue(newPercent);
    const maxAmount = getMaxAmount();
    const valAmount = (maxAmount * newPercent) / 100;
    setInputValue(fmt(valAmount, 4));
    setRawAmount(valAmount);
  };

  const getModalHeading = () => {
    switch (modalAction) {
      case "Stake":
        return "Ready to stake your SNOVA?";
      case "Unstake":
        return "Unstake your SNOVA now?";
      case "Compound":
        return "Compound your rewards into your stake?";
      case "Claim":
        return "Claim your SNOVA rewards now?";
      default:
        return "";
    }
  };

  const getModalWarning = () => {
    switch (modalAction) {
      case "Stake":
        return "This action cannot be undone.";
      case "Unstake":
        return "Your rewards will stop accruing for this amount.";
      case "Compound":
        return "This will add your claimable rewards to your stake.";
      case "Claim":
        return "Claimed rewards don't generate revenue until staked again.";
      default:
        return "";
    }
  };

  const getModalAmount = () => {
    if (modalAction === "Stake" || modalAction === "Unstake") {
      return rawAmount;
    }
    if (modalAction === "Claim" || modalAction === "Compound") {
      return claimableRewards;
    }
    return 0;
  };

  const tooltipPercentage = `${Math.round(sliderValue)}% of stakeable SNOVA`;
  const showTooltip =
    (isThumbHovered || isThumbFocused) && isConnected && getMaxAmount() > 0;

  const [cooldown, setCooldown] = useState("");

  useEffect(() => {
    let timer;
    if (
      !loading &&
      stakingData &&
      nextAvailableActionTimestamp &&
      isConnected
    ) {
      timer = setInterval(() => {
        const now = new Date();
        const target = new Date(nextAvailableActionTimestamp);
        const diff = target - now;
        if (diff > 0) {
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          const newCooldown = `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
          setCooldown(newCooldown);
        } else {
          setCooldown("00:00:00");
          // Once cooldown hits zero, we refetch data to update state
          if (stakedSnovaBalance !== 0) {
            fetchStakingData();
          }
        }
      }, 1000);
    } else {
      setCooldown("");
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [loading, stakingData, nextAvailableActionTimestamp, isConnected]);

  let showStaticCooldownMessage = false;
  let showDynamicTimer = false;
  let dynamicTimerLabel = "";

  if (!isConnected) {
    showStaticCooldownMessage = true;
  } else {
    if (!loading && stakingData) {
      if (claimableRewards > 0) {
        if (
          cooldown &&
          cooldown !== "00:00:00" &&
          nextAvailableActionTimestamp &&
          stakedSnovaBalance > 0
        ) {
          showDynamicTimer = true;
          dynamicTimerLabel = "Next reward accrual in:";
        }
      } else {
        if (stakedSnovaBalance > 0) {
          if (
            cooldown &&
            cooldown !== "00:00:00" &&
            nextAvailableActionTimestamp
          ) {
            showDynamicTimer = true;
            dynamicTimerLabel = "Available in:";
          }
        } else {
          showStaticCooldownMessage = true;
        }
      }
    }
  }

  return (
    <div className="sc-iaFMSK fkVKpM">
      <div className="sc-jgyNOb lfUtvf padding220">
        <div className="sc-cgFNHA dpCZJJ">
          <div className="sc-jDUNyp gCXHAi custom-ui-container">
            <h1 className="referral-head">
              {t("Staking.banner.stakingInsights")}
            </h1>
            <div
              className="container-content flex flex-col gap-3 lg:flex-row lg:gap-5"
              bis_skin_checked={1}
            >
              {/* Stats Cards */}
              <div
                className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto"
                bis_skin_checked={1}
              >
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                <img src={currentAPR} style={{ height: "36px" }} alt="APR" />
                <p className="font-normal typography-caption1">
                  {t("Staking.banner.currentAPR")}
                </p>
                <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                  <span className="truncate">75%</span>
                </h3>
              </div>

              <div
                className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto"
                bis_skin_checked={1}
              >
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                <img
                  src={totalStaked}
                  style={{ height: "36px" }}
                  alt="Total Staked"
                />
                <p className="font-normal typography-caption1">
                  {t("Staking.banner.totalStaked")}
                </p>
                <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                  <span className="truncate">
                    {fmt(totalValueLocked, 4)} SNOVA
                  </span>
                </h3>
              </div>

              <div
                className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto"
                bis_skin_checked={1}
              >
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                <img
                  src={yourHoldings}
                  style={{ height: "36px" }}
                  alt="Your Holdings"
                />
                <p className="font-normal typography-caption1">
                  {t("Staking.banner.yourHoldings")}
                </p>
                <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                  <span className="truncate">
                    {fmt(totalSnovaHoldings, 4)} SNOVA
                  </span>
                </h3>
              </div>

              <div
                className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto"
                bis_skin_checked={1}
              >
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                <img
                  src={unstakedBalance}
                  style={{ height: "36px" }}
                  alt="Unstaked Balance"
                />
                <p className="font-normal typography-caption1">
                  {t("Staking.banner.unstakedBalance")}
                </p>
                <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                  <span className="truncate">
                    {fmt(unstakedSnova, 4)} SNOVA
                  </span>
                </h3>
              </div>
            </div>

            <div className="text-left left-cont">
              <ol className="relative flex flex-col gap-10 mb-6 border-[#301748] border-s">
                <li className="flex ms-6">
                  <span className="absolute top-6 flex items-center justify-center w-9 h-9 text-gray-300 bg-[#301748] rounded-full -start-5 ring-2 ring-white dark:ring-[#3B334C] num -left-[1.2em]">
                    1
                  </span>
                  <span className="flex flex-col text-left">
                    <h3 className="text-lg font-bold leading-tight text-white">
                      {t("Staking.banner.dontHaveSnova")}
                    </h3>
                    <p className="-mt-1 text-gray-400 text-md">
                      {t("Staking.banner.joinPresale")}{" "}
                      <span className="underline">
                        <Link to="/">{t("Staking.banner.presale")}</Link>
                      </span>{" "}
                      {t("Staking.banner.startStaking")}
                    </p>
                  </span>
                </li>
                <li className="flex ms-6">
                  <span className="absolute top-6 flex items-center justify-center w-9 h-9 text-gray-300 bg-[#301748] rounded-full -start-5 ring-2 ring-white dark:ring-[#3B334C] num -left-[1.2em]">
                    2
                  </span>
                  <span className="flex flex-col text-left">
                    <h3 className="text-lg font-bold leading-tight text-white">
                      {t("Staking.banner.readyToStake")}
                    </h3>
                    <p className="-mt-1 text-gray-400 text-md">
                      {t("Staking.banner.stakeNow")}
                    </p>
                  </span>
                </li>
                <li className="flex ms-6">
                  <span className="absolute  top-6 flex items-center justify-center w-9 h-9 text-gray-300 bg-[#301748] rounded-full -start-5 ring-2 ring-white dark:ring-[#3B334C] num -left-[1.2em]">
                    3
                  </span>
                  <span className="flex flex-col text-left">
                    <h3 className="text-lg font-bold leading-tight text-white">
                      {t("Staking.banner.compoundForGrowth")}
                    </h3>
                    <p className="-mt-1 text-gray-400 text-md">
                      {t("Staking.banner.reinvestRewards")}
                    </p>
                  </span>
                </li>
              </ol>
            </div>

            <div
              className="sc-gikAfH sc-HLtgk desktop-faq  jHniMi eIIHDG"
              style={{
                backgroundColor: "rgb(28, 28, 28)",
                border: "1px solid rgb(80, 80, 80)",
                borderRadius: 16,
                overflow: "hidden",
                color: "rgb(169, 169, 169)",
                padding: "12px 16px",
              }}
            >
              <div
                className="sc-gswNZR sc-iBYQkv sc-ftTHYK sc-ifasWZ gIgClI cuevGy zgtPq"
                style={{
                  backgroundColor: "rgb(28, 28, 28)",
                  cursor: "default",
                }}
                onClick={() => setIsOpen(!isOpen)}
              >
                <div
                  className="css-dzm8si ftsize"
                  style={{ textTransform: "uppercase" }}
                >
                  FAQS
                </div>
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                >
                  <path
                    d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                    fill="#fc6432"
                  />
                </svg>
              </div>

              <div
                className="sc-gVzlyS gwRDJF mini-faq"
                style={{
                  opacity: isOpen ? 1 : 0,
                  overflow: "hidden",
                  transition: "max-height 0.3s ease, opacity 0.3s ease",
                  maxHeight: isOpen ? "500px" : "0px",
                }}
              >
                <div style={{ marginTop: "12px" }}></div>
                <div className="faq-map">
                  {faqData.map((item, index) => (
                    <div
                      key={index}
                      className="faq-item faq-slide ui-flex ui-w-full ui-flex-col ui-hover ui-border-x-0 ui-border-solid ui-border-[#131314] ui-pb-4 ui-pr-6 ui-pt-4 ui-border-y"
                    >
                      <div
                        onClick={() => toggleFAQ(index)}
                        className={`${
                          activeIndex === index ? "orange-color" : ""
                        } faq-question ui-space-between ui-flex ui-w-full ui-cursor-pointer ui-items-center ui-fill-txt-2 ui-py-2 ui-transition-all hover:ui-fill-primary hover:ui-text-primary`}
                      >
                        <div className="b1sb ui-flex-1">{item.question}</div>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className={`ui-items-center ui-justify-center ${
                            activeIndex === index ? "" : "ui-rotate-180"
                          }`}
                        >
                          <path
                            d="M7.5 13.1786L7.5 15L12 15L16.5 15L16.5 13.1786L12 9L7.5 13.1786Z"
                            fill={`${
                              activeIndex === index ? "#fc6432" : "white"
                            }`}
                          ></path>
                        </svg>
                      </div>
                      <div
                        className={`faq-answer b2 ui-max-w-[850px] ui-transition-all ${
                          activeIndex === index
                            ? "ui-block ui-h-auto ui-pt-2 ui-opacity-100"
                            : "ui-hidden ui-h-0 ui-pt-0 ui-opacity-0"
                        }`}
                      >
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="sc-ePVRMS kWWrlt">
            <h1 className="referral-head">
              {t("Staking.banner.manageYourStaking")}
            </h1>
            <div className="stakingForm">
              <div className="ant-space-item mbtool">
                <div className="ant-flex bjKEmbw css-dev-only-do-not-override-1q8nizr ant-flex-align-end ant-flex-justify-space-between">
                  <div className=" css-lb1r9s">
                    <div
                      onClick={StakeIt}
                      className={
                        isStake
                          ? "active css-grbf26 relative"
                          : "hover-one css-grbf26 relative auto-overflow"
                      }
                    >
                      <div className="css-gmuwbf ">
                        {isStake && (
                          <>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                          </>
                        )}
                        <p className="text-[16px] font-[500] tracking-[-0.8px] laptop:tracking-normal">
                          {t("Staking.banner.stake")}{" "}
                          <span
                            className={`${isStake ? "oranger" : "orangerbg"}`}
                          >
                            {t("Staking.banner.apy")}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      onClick={unStakeIt}
                      className={
                        isStake
                          ? "hover-one css-grbf26 relative"
                          : "active css-grbf26 relative"
                      }
                    >
                      <div className="css-gmuwbf">
                        {!isStake && (
                          <>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                          </>
                        )}
                        <p className="text-[16px] font-[500] tracking-[-0.8px] laptop:tracking-normal">
                          {t("Staking.banner.unstake")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sections-container">
                {isStake ? (
                  <>
                    <div className="sc-inJzbo sc-KAmpQ tBbNw hPfNbk new-bga">
                      <div className="css-4is5xf">
                        {t("Staking.banner.yourStakedSnova")}
                      </div>
                      <div className="css-yzh01t" style={{ gap: 8 }}>
                        <img
                          src={snovaCoin}
                          alt="snova-logo"
                          width="20px"
                          height="20px"
                        />{" "}
                        {fmt(stakedSnovaBalance, 4)} SNOVA
                      </div>
                    </div>
                    <div className="sc-hyDvVc flpGHV">
                      <div
                        className={`sc-inJzbo sc-ihPmgF tBbNw cZUnBP ${
                          isBlurred3 ? "blur-comp" : ""
                        }`}
                      >
                        <div
                          color="#A9A9A9"
                          className="sc-gswNZR sc-iBYQkv sc-ftTHYK jDUKIX cuevGy zgtPq"
                        >
                          <Link
                            to="/"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              style={{ height: "16px", marginRight: "7px" }}
                              src={buySnova}
                              alt="buy-snova-coins"
                            />
                            <div className="css-5h0skw">
                              {t("Staking.banner.buySnova")}
                            </div>
                          </Link>
                        </div>
                        <div className="sc-gfbthv mvqrr">
                          <div
                            width="100%"
                            className="sc-gswNZR sc-iBYQkv sc-ftTHYK dsdNeT cuevGy zgtPq"
                          >
                            <div className="sc-gswNZR sc-iBYQkv sc-jrcTuL gIgClI dbDzoQ fNxtIS">
                              <button
                                className="sc-hbhwkO fgaEeH"
                                onClick={!disableSlider ? handleMax : undefined}
                              >
                                {t("Staking.banner.max")}
                              </button>
                              <button
                                className="sc-hbhwkO fgaEeH"
                                onClick={
                                  !disableSlider ? handleHalf : undefined
                                }
                              >
                                {t("Staking.banner.half")}
                              </button>
                            </div>
                            <div
                              color="#A9A9A9"
                              className="sc-gswNZR thissvg sc-iBYQkv sc-jrcTuL jDUKIX gBzCzi jkIBMj"
                              onClick={() => {
                                if (!isConnected) open();
                              }}
                              style={{
                                cursor: !isConnected ? "pointer" : "default",
                              }}
                            >
                              <p className="balance-p">
                                {t("Staking.banner.balance")}:{" "}
                                {isConnected ? (
                                  <span
                                    onClick={
                                      !disableSlider ? () => handleMax() : null
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {fmt(unstakedSnova, 4)} SNOVA
                                  </span>
                                ) : (
                                  <span>
                                    {t("Staking.banner.connect")}{" "}
                                    <span className="desk-only">
                                      {t("Staking.banner.yourWallet")}
                                    </span>
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="sc-gswNZR sc-iBYQkv sc-ftTHYK gIgClI cuevGy zgtPq">
                            <input
                              inputMode="decimal"
                              title={0.0}
                              autoComplete="off"
                              autoCorrect="off"
                              type="text"
                              pattern="^[0-9]*[.,]?[0-9]*$"
                              placeholder={0.0}
                              minLength={1}
                              maxLength={79}
                              spellCheck="false"
                              className="sc-ehVsHJ ibsvru"
                              value={inputValue}
                              onChange={handleInputChange}
                              disabled={disableSlider}
                            />
                            <span
                              style={{
                                color: "gray",
                                fontSize: 14,
                                marginRight: 6,
                              }}
                            >
                              ${fmt(amount * price, 2)}
                            </span>
                            <div
                              className="sc-gVnjqf eitkWI"
                              style={{ alignItems: "center" }}
                            >
                              <img
                                src={snovaCoin}
                                alt="snova-logo"
                                className="isImg"
                                width="24px"
                                height="24px"
                              />
                              SNOVA
                            </div>
                          </div>
                        </div>

                        <div className="inputs">
                          <div
                            className="mantine-Slider-root mantine-16d7kiv"
                            tabIndex={-1}
                          >
                            <div
                              className="mantine-12p37cx mantine-Slider-trackContainer"
                              style={{
                                userSelect: "none",
                                cursor: disableSlider
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              ref={sliderTrackRef}
                              onMouseDown={
                                !disableSlider ? onTrackMouseDown : undefined
                              }
                            >
                              <div className="mantine-1tdql7g mantine-Slider-track">
                                <div
                                  className="mantine-Slider-bar mantine-qjjy1c"
                                  style={{ width: `${sliderValue}%` }}
                                />
                                <div
                                  className={`mantine-Slider-thumb mantine-1n7v7zl slider-thumb ${
                                    showTooltip ? "thumb-active" : ""
                                  }`}
                                  tabIndex={0}
                                  role="slider"
                                  aria-label="slider"
                                  aria-valuemax={100}
                                  aria-valuemin={0}
                                  aria-valuenow={sliderValue}
                                  style={{ left: `${sliderValue}%` }}
                                  ref={thumbRef}
                                  onMouseDown={
                                    !disableSlider
                                      ? onThumbMouseDown
                                      : undefined
                                  }
                                  onMouseEnter={handleThumbMouseEnter}
                                  onMouseLeave={handleThumbMouseLeave}
                                  onFocus={handleThumbFocus}
                                  onBlur={handleThumbBlur}
                                />
                                {showTooltip && (
                                  <div
                                    className="slider-tooltip"
                                    style={{ left: `${sliderValue}%` }}
                                  >
                                    {tooltipPercentage}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mantine-s2z3jd mantine-Slider-marksContainer">
                              <div className="mantine-Slider-markWrapper mantine-uphwyh">
                                <div className="mantine-Slider-mark mantine-Slider-markFilled mantine-1bnwqbd" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  0%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-zose1j">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  25%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-1n5vmf9">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  50%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-jkqqu7">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  75%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-1dcgt5g">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  100%
                                </div>
                              </div>
                            </div>
                            <input type="hidden" value={sliderValue} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="sc-inJzbo sc-KAmpQ tBbNw hPfNbk new-bga">
                      <div className="css-4is5xf">
                        {t("Staking.banner.yourStakedSnova")}
                      </div>
                      <div className="css-yzh01t" style={{ gap: 8 }}>
                        <img
                          src={snovaCoin}
                          alt="snova-logo"
                          width="20px"
                          height="20px"
                        />{" "}
                        {fmt(stakedSnovaBalance, 4)} SNOVA
                      </div>
                    </div>
                    <div className="sc-hyDvVc flpGHV">
                      <div
                        className={`sc-inJzbo sc-ihPmgF tBbNw cZUnBP ${
                          isBlurred3 ? "blur-comp" : ""
                        }`}
                      >
                        <div
                          color="#A9A9A9"
                          className="sc-gswNZR sc-iBYQkv sc-ftTHYK jDUKIX cuevGy zgtPq"
                        >
                          <Link
                            to="/"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              style={{ height: "16px", marginRight: "7px" }}
                              src={buySnova}
                              alt="buy-snova-coins"
                            />
                            <div className="css-5h0skw">
                              {t("Staking.banner.buySnova")}
                            </div>
                          </Link>
                        </div>
                        <div className="sc-gfbthv mvqrr">
                          <div
                            width="100%"
                            className="sc-gswNZR sc-iBYQkv sc-ftTHYK dsdNeT cuevGy zgtPq"
                          >
                            <div className="sc-gswNZR sc-iBYQkv sc-jrcTuL gIgClI dbDzoQ fNxtIS">
                              <button
                                className="sc-hbhwkO fgaEeH"
                                onClick={!disableSlider ? handleMax : undefined}
                              >
                                {t("Staking.banner.max")}
                              </button>
                              <button
                                className="sc-hbhwkO fgaEeH"
                                onClick={
                                  !disableSlider ? handleHalf : undefined
                                }
                              >
                                {t("Staking.banner.half")}
                              </button>
                            </div>
                            <div
                              color="#A9A9A9"
                              className="sc-gswNZR thissvg sc-iBYQkv sc-jrcTuL jDUKIX gBzCzi jkIBMj"
                              onClick={() => {
                                if (!isConnected) open();
                              }}
                              style={{
                                cursor: !isConnected ? "pointer" : "default",
                              }}
                            >
                              <p className="balance-p">
                                {t("Staking.banner.balance")}:{" "}
                                {isConnected ? (
                                  <span
                                    onClick={
                                      !disableSlider ? () => handleMax() : null
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {fmt(stakedSnovaBalance, 4)} SNOVA
                                  </span>
                                ) : (
                                  <span>
                                    {t("Staking.banner.connect")}{" "}
                                    <span className="desk-only">
                                      {t("Staking.banner.yourWallet")}
                                    </span>
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="sc-gswNZR sc-iBYQkv sc-ftTHYK gIgClI cuevGy zgtPq">
                            <input
                              inputMode="decimal"
                              title={0.0}
                              autoComplete="off"
                              autoCorrect="off"
                              type="text"
                              pattern="^[0-9]*[.,]?[0-9]*$"
                              placeholder={0.0}
                              minLength={1}
                              maxLength={79}
                              spellCheck="false"
                              className="sc-ehVsHJ ibsvru"
                              value={inputValue}
                              onChange={handleInputChange}
                              disabled={disableSlider}
                            />
                            <span
                              style={{
                                color: "gray",
                                fontSize: 14,
                                marginRight: 6,
                              }}
                            >
                              ${fmt(amount * price, 2)}
                            </span>
                            <div
                              className="sc-gVnjqf eitkWI"
                              style={{ alignItems: "center" }}
                            >
                              <img
                                className="isImg"
                                src={snovaCoin}
                                alt="snova-logo"
                              />
                              SNOVA
                            </div>
                          </div>
                        </div>
                        <div className="inputs">
                          <div
                            className="mantine-Slider-root mantine-16d7kiv"
                            tabIndex={-1}
                          >
                            <div
                              className="mantine-12p37cx mantine-Slider-trackContainer"
                              style={{
                                userSelect: "none",
                                cursor: disableSlider
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              ref={sliderTrackRef}
                              onMouseDown={
                                !disableSlider ? onTrackMouseDown : undefined
                              }
                            >
                              <div className="mantine-1tdql7g mantine-Slider-track">
                                <div
                                  className="mantine-Slider-bar mantine-qjjy1c"
                                  style={{ width: `${sliderValue}%` }}
                                />
                                <div
                                  className={`mantine-Slider-thumb mantine-1n7v7zl slider-thumb ${
                                    showTooltip ? "thumb-active" : ""
                                  }`}
                                  tabIndex={0}
                                  role="slider"
                                  aria-label="slider"
                                  aria-valuemax={100}
                                  aria-valuemin={0}
                                  aria-valuenow={sliderValue}
                                  style={{ left: `${sliderValue}%` }}
                                  ref={thumbRef}
                                  onMouseDown={
                                    !disableSlider
                                      ? onThumbMouseDown
                                      : undefined
                                  }
                                  onMouseEnter={handleThumbMouseEnter}
                                  onMouseLeave={handleThumbMouseLeave}
                                  onFocus={handleThumbFocus}
                                  onBlur={handleThumbBlur}
                                />
                                {showTooltip && (
                                  <div
                                    className="slider-tooltip"
                                    style={{ left: `${sliderValue}%` }}
                                  >
                                    {tooltipPercentage}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mantine-s2z3jd mantine-Slider-marksContainer">
                              <div className="mantine-Slider-markWrapper mantine-uphwyh">
                                <div className="mantine-Slider-mark mantine-Slider-markFilled mantine-1bnwqbd" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  0%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-zose1j">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  25%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-1n5vmf9">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  50%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-jkqqu7">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  75%
                                </div>
                              </div>
                              <div className="mantine-Slider-markWrapper mantine-1dcgt5g">
                                <div className="mantine-nnvqx1 mantine-Slider-mark" />
                                <div className="mantine-1a7pf42 mantine-Slider-markLabel">
                                  100%
                                </div>
                              </div>
                            </div>
                            <input type="hidden" value={sliderValue} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {isBlurred3 && (
                  <img
                    src={LoaderIcon}
                    alt="Overlay"
                    className="overlay-icon-staking"
                  />
                )}
              </div>
              {/* {isConnected && (
                <div
                  style={{
                    marginTop: "10px",
                    color: "#D6D6D6",
                    fontSize: "14px",
                    fontFamily: "'Matter Mono', monospace",
                    fontWeight: 500,
                  }}
                >
                  {t("Staking.banner.minimumStake")}: ${minimumStakeUSD}{" "}
                  {t("Staking.banner.inSnova")}
                </div>
              )} */}

              {isConnected ? (
                isStake ? (
                  isStakingPaused ? (
                    <TooltipButton text={t("Staking.banner.stakingPaused")}>
                      <button
                        id="defaultButton"
                        className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                        type="button"
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(252, 100, 50)",
                          color: "rgb(255, 255, 255)",
                          boxShadow: "rgb(192, 64, 0) 6px 6px",
                          marginTop: "20px",
                          position: "relative",
                        }}
                        disabled={
                          isBlurred3 ||
                          isStakingPaused ||
                          rawAmount * price < minimumStakeUSD ||
                          unstakedSnova < rawAmount
                        }
                        onClick={() => handleAction("Stake")}
                      >
                        {isBlurred3 ? (
                          t("Staking.banner.staking")
                        ) : (
                          <>
                            <img
                              src={deposit}
                              style={{
                                height: "20px",
                                marginRight: "5px",
                              }}
                              alt="stake-icon"
                            ></img>
                            {t("Staking.banner.stakeSnovaTokens")}
                          </>
                        )}
                      </button>
                    </TooltipButton>
                  ) : (
                    <button
                      id="defaultButton"
                      className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                        marginTop: "20px",
                        position: "relative",
                      }}
                      disabled={
                        isBlurred3 ||
                        rawAmount * price < minimumStakeUSD ||
                        unstakedSnova < rawAmount
                      }
                      onClick={() => handleAction("Stake")}
                    >
                      {rawAmount * price < minimumStakeUSD ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              marginRight: "5px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ExclamationIcon />
                          </span>
                          {t("Staking.banner.minimumStake")}: ${minimumStakeUSD}{" "}
                          {t("Staking.banner.inSnova")}
                        </div>
                      ) : isBlurred3 ? (
                        t("Staking.banner.staking")
                      ) : (
                        <>
                          <img
                            src={deposit}
                            style={{
                              height: "20px",
                              marginRight: "5px",
                            }}
                            alt="stake-icon"
                          />
                          {t("Staking.banner.stakeSnovaTokens")}
                        </>
                      )}
                    </button>
                  )
                ) : (
                  <button
                    id="defaultButton"
                    className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                    type="button"
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(252, 100, 50)",
                      color: "rgb(255, 255, 255)",
                      boxShadow: "rgb(192, 64, 0) 6px 6px",
                      marginTop: "20px",
                    }}
                    disabled={
                      isBlurred3 ||
                      stakedSnovaBalance < rawAmount ||
                      rawAmount <= 0
                    }
                    onClick={() => handleAction("Unstake")}
                  >
                    {isBlurred3 ? (
                      t("Staking.banner.unstaking")
                    ) : (
                      <>
                        <img
                          src={withdraw}
                          style={{ height: "20px", marginRight: "5px" }}
                          alt="unstake-icon"
                        ></img>
                        {t("Staking.banner.unstakeSnovaTokens")}
                      </>
                    )}
                  </button>
                )
              ) : (
                <button
                  id="defaultButton"
                  className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary "
                  type="button"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(252, 100, 50)",
                    color: "rgb(255, 255, 255)",
                    boxShadow: "rgb(192, 64, 0) 6px 6px",
                    marginTop: "20px",
                  }}
                  onClick={() => open()}
                >
                  <svg
                    width="20"
                    className="wallet-icon no-tp this20"
                    viewBox="0 0 16 15"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5px" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                    ></path>
                  </svg>
                  {t("Staking.banner.connectWallet")}
                </button>
              )}
            </div>
            <div
              className="ui-border ui-border-solid ui-p-4 md:ui-p-6 ui-border-tile-3-elev flex flex-col ui-gap-4 xs:ui-min-w-[360px] ui-flex-1 ui-items-center"
              // style={{ marginTop: "55px" }}
            >
              <div className="ui-flex ui-w-full ui-justify-between">
                <div className="ui-flex ui-gap-5">
                  <div className="flex flex-col justify-between">
                    <div className="r1b text-txt-4 flex items-center uppercase">
                      {t("Staking.banner.totalRewardsEarned")}
                      <span
                        role="img"
                        style={{
                          marginLeft: 5,
                          transform: "translateY(-1.5px)",
                        }}
                      >
                        <Tooltip text={t("Staking.banner.totalRewardsTooltip")}>
                          <img
                            src={totalRewards}
                            style={{ height: "16px" }}
                            alt={t("Staking.banner.totalRewards")}
                          />
                        </Tooltip>
                      </span>
                    </div>
                    <div className="f3b">{fmt(totalEarnings, 4)} SNOVA</div>
                    <div className="r1b ui-text-txt-2 f4b">
                      ${fmt(totalEarningsValue, 2)}
                    </div>
                  </div>
                </div>
                <div className="ui-flex" />
              </div>
              <div className="ui-flex ui-flex-col ui-items-center">
                <img
                  src={snovaCoin}
                  alt="snova-logo"
                  width="40px"
                  height="40px"
                />
                <div className="r2b ui-text-txt-1 ui-mt-2 ui-uppercase">
                  {t("Staking.banner.claimableRewards")}
                  <span
                    role="img"
                    style={{
                      marginLeft: 5,
                      display: "inline-flex",
                      transform: "translateY(2px)",
                    }}
                  >
                    <Tooltip text={t("Staking.banner.claimableRewardsTooltip")}>
                      <img
                        src={totalRewards}
                        style={{ height: "16px" }}
                        alt=""
                      />
                    </Tooltip>
                  </span>
                </div>
                <div className="f1">{fmt(claimableRewards, 4)}</div>
                <div className="r1b ui-text-txt-2">
                  ${fmt(claimableRewardsValue, 2)}
                </div>
              </div>
              <div className="ui-relative ui-w-full">
                {!isConnected ? (
                  <button
                    id="defaultButton"
                    className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                    type="button"
                    style={{
                      marginLeft: "14%",
                      width: "72%",
                      backgroundColor: "rgb(252, 100, 50)",
                      color: "rgb(255, 255, 255)",
                      boxShadow: "rgb(192, 64, 0) 6px 6px",
                      marginTop: "20px",
                    }}
                    onClick={() => open()}
                  >
                    <svg
                      width="20"
                      className="wallet-icon no-tp this20"
                      viewBox="0 0 16 15"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "5px" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                      ></path>
                    </svg>
                    {t("Staking.banner.connectWallet")}
                  </button>
                ) : (
                  <div className="ui-flex ui-flex-col ui-items-center">
                    <div className="ui-flex ui-flex-wrap ui-items-center ui-justify-center ui-gap-3">
                      <button
                        className="ui-uppercase compound-button btn-lga w-[190px]"
                        onClick={() => handleAction("Compound")}
                        disabled={claimableRewards < 0.0001}
                      >
                        {t("Staking.banner.compound")}
                      </button>
                      <button
                        className="ui-uppercase claim-button btn-lga w-[190px]"
                        onClick={() => handleAction("Claim")}
                        disabled={claimableRewards < 0.0001}
                      >
                        {t("Staking.banner.claim")}
                      </button>
                    </div>
                    {showDynamicTimer && (
                      <p
                        className="cooldown-timer"
                        style={{
                          fontSize: "12px",
                          color: "#a5a5a5",
                          textAlign: "center",
                          marginTop: "8px",
                        }}
                      >
                        {dynamicTimerLabel} {cooldown}
                      </p>
                    )}
                  </div>
                )}
                <div className="b3 ui-text-txt-2 ui-absolute -ui-bottom-7 ui-mx-auto ui-w-full ui-text-center ui-invisible"></div>
              </div>
              <div className="ui-flex ui-flex-col ui-gap-5 ui-mt-4 ui-items-center">
                {showStaticCooldownMessage && (
                  <div className="b3 -ui-mb-2 ui-mt-1 ui-flex ui-w-full ui-justify-center ui-text-center ui-h-[20px] ui-text-disabled ui-gap-1 ui-invisible">
                    <div className="ui-w-fit">
                      <div
                        data-tooltip-id="unlock-request-period-lock-toke-box "
                        className="ui-flexa"
                      >
                        <p>{t("Staking.banner.cooldownMessage")}</p>
                      </div>
                    </div>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dab-k no-dbk">
            <h1 className="referral-head">
              {t("Staking.banner.stakingHistory")}
            </h1>
            <div
              className="page_tableContainer__p8Vj_ max-diffee"
              style={{ background: "rgb(28, 28, 28)" }}
            >
              <div
                colSpan="4"
                className="text-center table-wrappers text-white rounded-[30px] bg-baseBg flex flex-col text-left w-full p-[32px] sm:p-[20px] py-20"
              >
                <div className="p-0 bg-black rounded-[20px] w-full relative">
                  {!isConnected && isDesk ? (
                    <table className="w-full text-left table-fixed-empty-state border-collapse">
                      <tbody>
                        <tr className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa">
                          <td className="border-b border-boa" colSpan="3">
                            <div
                              className="flex flex-col items-center justify-center text-center text-white py-16 px-4"
                              style={{ height: "320px" }}
                            >
                              <span className="rt-Text sc-qZrbh items-center iHUxZB">
                                <p
                                  className="secondary-text text-sm text-gray-300"
                                  style={{ fontSize: "16px", color: "#D6D6D6" }}
                                >
                                  {t("Staking.banner.connectWalletToView")}
                                </p>
                              </span>

                              <button
                                id="defaultButton"
                                className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                                type="button"
                                style={{
                                  width: "50%",
                                  backgroundColor: "rgb(252, 100, 50)",
                                  color: "rgb(255, 255, 255)",
                                  boxShadow: "rgb(192, 64, 0) 6px 6px",
                                  marginTop: "20px",
                                }}
                                onClick={() => open()}
                              >
                                <svg
                                  width="20"
                                  className="wallet-icon no-tp this20"
                                  viewBox="0 0 16 15"
                                  fill="white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ marginRight: "5px" }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                                  ></path>
                                </svg>
                                {t("Staking.banner.connectWallet")}
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : !isConnected && !isDesk ? (
                    <table className="w-full text-left table-fixed-empty-state border-collapse">
                      <tbody>
                        <div className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa">
                          <td className="border-b border-boa" colSpan="3">
                            <div
                              className="flex flex-col items-center justify-center text-center text-white py-16 px-4"
                              style={{ height: "320px" }}
                            >
                              <span className="rt-Text sc-qZrbh items-center iHUxZB">
                                <p
                                  className="secondary-text text-sm text-gray-300"
                                  style={{ fontSize: "16px", color: "#D6D6D6" }}
                                >
                                  {t("Staking.banner.connectWalletToView")}
                                </p>
                              </span>

                              <button
                                id="defaultButton"
                                className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                                type="button"
                                style={{
                                  width: "90%",
                                  backgroundColor: "rgb(252, 100, 50)",
                                  color: "rgb(255, 255, 255)",
                                  boxShadow: "rgb(192, 64, 0) 6px 6px",
                                  marginTop: "20px",
                                }}
                                onClick={() => open()}
                              >
                                <svg
                                  width="20"
                                  className="wallet-icon no-tp this20"
                                  viewBox="0 0 16 15"
                                  fill="white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ marginRight: "5px" }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                                  ></path>
                                </svg>
                                {t("Staking.banner.connectWallet")}
                              </button>
                            </div>
                          </td>
                        </div>
                      </tbody>
                    </table>
                  ) : stakingTransactions.length === 0 && isDesk ? (
                    <table className="w-full text-left table-fixed-empty-state border-collapse">
                      <tbody>
                        <tr className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa">
                          <td className="border-b border-boa" colSpan="3">
                            <div
                              className="flex flex-col items-center justify-center text-center text-white py-16 px-4"
                              style={{ height: "320px" }}
                            >
                              <img
                                src={folderOpen}
                                alt="Folder Open"
                                className="mx-auto mb-3"
                                style={{ width: "45px", height: "45px" }}
                              />
                              <span
                                className="rt-Text sc-qZrbh items-center iHUxZB"
                                style={{
                                  fontSize: "14px",
                                  paddingTop: "10px",
                                }}
                              >
                                <div className="max-w-sm mx-auto mb-6">
                                  <p className="primary-text font-semibold mb-2 text-xl text-white capitalize">
                                    {t("Staking.banner.noStakingActivity")}
                                  </p>
                                  {totalSnovaHoldings === 0 ? (
                                    <>
                                      <p
                                        className="secondary-text text-sm text-[#8D8D8D]"
                                        style={{ marginBottom: "24px" }}
                                      >
                                        {t("Staking.banner.noSnova")}
                                      </p>
                                      <div className="flex main-gb">
                                        <Link
                                          to="/"
                                          style={{ textDecoration: "none" }}
                                        >
                                          <button
                                            className="ant-btn button-bna custom-button-font flex items-center content jad css-dev-only-do-not-override-1q8nizr ant-btn-primary join-presale-btn"
                                            style={{
                                              marginLeft: "0%",
                                              width: "100%",
                                              backgroundColor: "#fc6432",
                                              color: "rgb(255, 255, 255)",
                                              border: "none",
                                              boxShadow: "6px 6px #C04000",
                                              fontSize: "12px !important",
                                            }}
                                          >
                                            <img
                                              src={rocketIcon}
                                              alt="Rocket Icon"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                marginLeft: "4px",
                                                marginRight: "4px",
                                                verticalAlign: "middle",
                                                flexShrink: 0,
                                              }}
                                            />
                                            {t("Staking.banner.joinThePresale")}
                                          </button>
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <p className="secondary-text text-sm text-gray-300">
                                      {t("Staking.banner.stakeSnova")}
                                    </p>
                                  )}
                                </div>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : stakingTransactions.length === 0 && !isDesk ? (
                    <table className="w-full text-left table-fixed-empty-state border-collapse">
                      <tbody>
                        <div className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa">
                          <td className="border-b border-boa" colSpan="3">
                            <div
                              className="flex flex-col items-center justify-center text-center text-white py-16 px-4"
                              style={{ height: "320px" }}
                            >
                              <img
                                src={folderOpen}
                                alt="Folder Open"
                                className="mx-auto mb-3"
                                style={{ width: "45px", height: "45px" }}
                              />
                              <span
                                className="rt-Text sc-qZrbh items-center iHUxZB"
                                style={{
                                  fontSize: "14px",
                                  paddingTop: "10px",
                                }}
                              >
                                <div className="max-w-sm mx-auto mb-6">
                                  <p className="primary-text font-semibold mb-2 text-xl text-white capitalize">
                                    {t("Staking.banner.noStakingActivity")}
                                  </p>
                                  {totalSnovaHoldings === 0 ? (
                                    <>
                                      <p
                                        className="secondary-text text-sm text-[#8D8D8D]"
                                        style={{ marginBottom: "24px" }}
                                      >
                                        {t("Staking.banner.noSnova")}
                                      </p>
                                      <div className="flex main-gb">
                                        <Link
                                          to="/"
                                          style={{ textDecoration: "none" }}
                                        >
                                          <button
                                            className="ant-btn button-bna custom-button-font flex items-center content jad css-dev-only-do-not-override-1q8nizr ant-btn-primary join-presale-btn"
                                            style={{
                                              marginLeft: "0%",
                                              width: "100%",
                                              backgroundColor: "#fc6432",
                                              color: "rgb(255, 255, 255)",
                                              border: "none",
                                              boxShadow: "6px 6px #C04000",
                                              fontSize: "12px !important",
                                            }}
                                          >
                                            <img
                                              src={rocketIcon}
                                              alt="Rocket Icon"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                marginLeft: "4px",
                                                marginRight: "4px",
                                                verticalAlign: "middle",
                                                flexShrink: 0,
                                              }}
                                            />
                                            {t("Staking.banner.joinThePresale")}
                                          </button>
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <p className="secondary-text text-sm text-gray-300">
                                      {t("Staking.banner.stakeSnova")}
                                    </p>
                                  )}
                                </div>
                              </span>
                            </div>
                          </td>
                        </div>
                      </tbody>
                    </table>
                  ) : (
                    stakingTransactions.map((txn, index) => (
                      <table
                        key={index}
                        className="w-full text-left table-fixed border-collapse"
                      >
                        <tbody>
                          <tr className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa">
                            <td className="align-bottom py-[10px] p-20p border-b border-boa">
                              <div className="flex flex-col">
                                <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                  {t("Staking.banner.date")}
                                </div>
                                <div className="flex items-center gap-1 smam date-sm">
                                  {formatDate(txn.timestamp)}
                                </div>
                              </div>
                            </td>
                            <td className="align-bottom py-[10px] p-20p border-b border-boa">
                              <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                {t("Staking.banner.action")}
                              </div>
                              <div className="flex items-center gap-1">
                                <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold date-sm">
                                  {(() => {
                                    let displayAction =
                                      txn.action_type.toLowerCase();
                                    if (displayAction === "stake") {
                                      displayAction = t(
                                        "Staking.banner.deposit"
                                      );
                                    } else if (displayAction === "unstake") {
                                      displayAction = t(
                                        "Staking.banner.withdraw"
                                      );
                                    } else {
                                      displayAction =
                                        displayAction.charAt(0).toUpperCase() +
                                        displayAction.slice(1);
                                    }
                                    return displayAction;
                                  })()}
                                </span>
                              </div>
                            </td>
                            <td className="align-bottom py-[10px] p-20p border-b border-boa">
                              <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                {t("Staking.banner.amount")}
                              </div>
                              <div className="flex items-center gap-1">
                                <img
                                  alt="asset icon"
                                  loading="lazy"
                                  width={24}
                                  height={24}
                                  decoding="async"
                                  className="mb-1 smallera"
                                  src={snovaCoin}
                                  style={{ color: "transparent" }}
                                />
                                <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold date-sm">
                                  {fmt(txn.amount, 4)}
                                </span>
                              </div>
                              <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                = ${fmt(txn.amount * price, 2)}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* done tr ^ */}
      {/* ========================== CONFIRM ACTION MODAL ========================== */}
      {modalAction && (
        <>
          <ModalPortal>
            <div>
              <div onClick={closeModal} className="modl-over-staking"></div>
              <div
                className="heo-staking"
                style={{
                  // position: "absolute",
                  // top: `${modalTop}px`,
                  top: "50%",
                  position: "fixed",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999999,
                }}
              >
                <div className="relative myaa-staking border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img
                        src={modalConfirmation}
                        className="purchase-summary"
                      />
                      {t("Staking.banner.confirmAction")}
                    </h2>
                    <div onClick={closeModal} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <h2
                    id="radix-:R1r6mH1:"
                    className="orange mibj"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    {getModalHeading()}
                  </h2>
                  <h3
                    className="text-center double-blk bjpar your-tokens-bg"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={snovaCoin}
                          alt="snova-logo"
                          width="24px"
                          height="24px"
                          style={{ marginRight: "8px" }}
                        />
                        <span
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#fc6432",
                          }}
                        >
                          {fmt(getModalAmount(), 4)} SNOVA
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#8d8d8d",
                          marginTop: "8px",
                          display: "inline-block",
                        }}
                      >
                        = ${fmt(getModalAmount() * price, 2)}
                      </span>
                    </div>
                  </h3>
                  <div className="flex main-gb" style={{ marginTop: "20px" }}>
                    <button
                      onClick={handleSubmit}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                        marginRight: "10px",
                      }}
                    >
                      {t("Staking.banner.confirm")}
                    </button>

                    <a
                      onClick={closeModal}
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 secondary-transform"
                      style={{
                        background: "transparent",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      {t("Staking.banner.cancel")}
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                    </a>
                  </div>
                  <div
                    className="text-text-tip text-2xs text-center"
                    style={{ marginTop: "20px" }}
                  >
                    <i>{getModalWarning()}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      )}
      {/* ========================== STAKING SUCCESS MODAL ========================== */}
      {stakingSuccess && (
        <>
          <ModalPortal>
            <div>
              <div className="modl-over-staking"></div>
              <div
                className="heo-staking"
                style={{
                  // position: "absolute",
                  // top: `${modalTop}px`,
                  position: "fixed",
                  top: "82%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "9999999",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img src={purchaseSummary} className="purchase-summary" />
                      {t("Staking.banner.stakingSummary")}
                    </h2>
                    <div className="cross-ic">
                      <svg
                        onClick={() => setstakingSuccess(false)}
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseSuccess}
                      className="purchase-success"
                      alt={t("Staking.banner.purchaseSuccess")}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("Staking.banner.congratulations")} <br />{" "}
                      {t("Staking.banner.stakingSuccessful")}
                    </h2>
                  </div>

                  {/* Staked Amount */}
                  <h3 className="text-center double-blk bjpar your-tokens-bg">
                    <div>
                      {/* rawAmount staked */}
                      <img src={snovaCoin} className="purchased-snova" />
                      <span className="your-tokens">
                        {fmt(rawAmount, 4)} SNOVA
                      </span>
                    </div>
                    <div>
                      <span className="your-tokens smaller-ft">
                        = ${fmt(rawAmount * price, 2)}
                      </span>
                    </div>
                  </h3>

                  <table className="table-cl">
                    <tbody>
                      <tr style={{ borderBottom: "1px solid #fc6432" }}>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-header no-tp nobot column-dark"
                        >
                          {t("Staking.banner.earningPotential")}
                        </td>
                        <td
                          className="table-cell no-tp column-light"
                          style={{ verticalAlign: "middle" }}
                        >
                          75% APR, up to 111.5% APY
                          <span
                            role="img"
                            className="cursor-pointer pointer"
                            style={{ marginLeft: 5 }}
                          >
                            <Tooltip
                              text={t("Staking.banner.earningPotentialTooltip")}
                            >
                              <img
                                src={totalRewards}
                                style={{ height: "18px", width: "18px" }}
                                alt="Total Rewards"
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header no-tp nmop column-light">
                          {t("Staking.banner.yourSnovaHoldings")}
                        </td>

                        <td
                          className="table-cell no-tp column-dark"
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {fmt(totalSnovaHoldings, 4)} SNOVA
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header column-dark">
                          {t("Staking.banner.yourStakedSnova")}
                        </td>
                        <td
                          className="table-cell column-light"
                          style={{
                            borderTop: "none",
                            verticalAlign: "middle",
                            borderBottom: "none",
                          }}
                        >
                          {fmt(stakedSnovaBalance, 4)} SNOVA
                          <span
                            role="img"
                            className="cursor-pointer pointer"
                            style={{ marginLeft: 5 }}
                          >
                            <Tooltip
                              text={t(
                                "Staking.banner.updatedStakedSnovaTooltip"
                              )}
                            >
                              <img
                                src={totalRewards}
                                style={{ height: "18px" }}
                                alt="Total Rewards"
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/*  */}
                  <div className="flex main-gb">
                    <button
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold btn-scale"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                      onClick={() => {
                        // Example: you could reset rawAmount here if needed
                        setstakingSuccess(false);
                      }}
                    >
                      {t("Staking.banner.stakeMore")}
                    </button>
                    <a
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 secondary-transform"
                      href="/dashboard"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                      {t("Staking.banner.manageStaking")}
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("Staking.banner.compoundDaily")}</i>
                  </div>

                  <div className="invite-card moti">
                    <h1 className="invite-title primary-color">
                      <img src={referralCoins} className="referral-coins" />{" "}
                      {t("Staking.banner.earn20")}
                    </h1>
                    <p className="invite-text">
                      {t("Staking.banner.inviteFriends")}
                    </p>
                    <div className="invite-link-container">
                      <div className="invite-link">
                        <span>{formatReferralLink(referralLink)}</span>
                        <button className="copy-button">
                          {copied ? (
                            <div className="_copyBtn_1fjrj_20 we">
                              <img src={done} alt="Copy" />
                            </div>
                          ) : (
                            <div
                              className="_copyBtn_1fjrj_20 we"
                              onClick={() => handleCopy(referralLink)}
                            >
                              <img src={CopyIco} alt="Copy" />
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      )}

      {/* ========================== UNSTAKING SUMMARY MODAL ========================== */}
      {unStakingSummary && (
        <>
          <ModalPortal>
            <div>
              <div className="modl-over-staking"></div>
              <div
                className="heo-staking"
                style={{
                  // position: "absolute",
                  // top: `${modalTop}px`,
                  position: "fixed",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "9999999",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img src={purchaseSummary} className="purchase-summary" />
                      {t("Staking.banner.unstakingSummary")}
                    </h2>
                    <div className="cross-ic">
                      <svg
                        onClick={() => setunStakingSummary(false)}
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseSuccess}
                      className="purchase-success"
                      alt={t("Staking.banner.purchaseSuccess")}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("Staking.banner.congratulations")} <br />{" "}
                      {t("Staking.banner.unstakingSuccessful")}
                    </h2>
                  </div>

                  {/* Unstaked amount: rawAmount */}
                  <h3 className="text-center double-blk bjpar your-tokens-bg">
                    <div>
                      <img
                        src={snovaCoin}
                        alt="snova coin"
                        className="purchased-snova"
                      />
                      <span className="your-tokens">
                        {fmt(rawAmount, 4)} SNOVA
                      </span>
                    </div>
                    <div>
                      <span className="your-tokens  smaller-ft">
                        = ${fmt(rawAmount * price, 2)}
                      </span>
                    </div>
                  </h3>

                  <table className="table-cl">
                    <tbody>
                      <tr style={{ borderBottom: "1px solid #fc6432" }}>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-header no-tp nobot column-dark"
                        >
                          {t("Staking.banner.yourSnovaHoldings")}
                        </td>
                        <td
                          className="table-cell no-tp column-light"
                          style={{
                            borderBottom: "none",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {fmt(totalSnovaHoldings, 4)} SNOVA
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header no-tp nmop column-light">
                          {t("Staking.banner.yourUnstakedSnova")}
                        </td>
                        <td
                          className="table-cell no-tp flex items-centers column-dark"
                          style={{
                            // Remove display: "flex" here
                            verticalAlign: "middle",
                          }}
                        >
                          {/* reflect updated unstakedSnova after this operation */}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {fmt(unstakedSnova, 4)} SNOVA
                            <span
                              role="img"
                              className="cursor-pointer pointer"
                              style={{ marginLeft: 5 }}
                            >
                              <Tooltip
                                text={t("Staking.banner.unstakedSnovaTooltip")}
                              >
                                <img
                                  src={totalRewards}
                                  style={{ height: "18px" }}
                                  alt={t("Staking.banner.totalRewards")}
                                />
                              </Tooltip>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header column-dark">
                          {t("Staking.banner.remainingStakedSnova")}
                        </td>
                        <td
                          className="table-cell column-light"
                          style={{
                            // remove display: "flex" and keep the td itself as table-cell
                            borderTop: "none",
                            borderBottom: "none",
                            verticalAlign: "middle", // optional for consistent vertical alignment
                          }}
                        >
                          {/* Wrap your flex layout in a separate div */}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {fmt(stakedSnovaBalance, 4)} SNOVA
                            <span
                              role="img"
                              className="cursor-pointer pointer"
                              style={{ marginLeft: 5 }}
                            >
                              <Tooltip
                                text={t(
                                  "Staking.banner.remainingStakedSnovaTooltip"
                                )}
                              >
                                <img
                                  src={totalRewards}
                                  style={{ height: "18px" }}
                                  alt="Total Rewards"
                                />
                              </Tooltip>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex main-gb">
                    <button
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold btn-scale"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                      onClick={() => {
                        // Example: you could reset rawAmount here if needed
                        setunStakingSummary(false);
                      }}
                    >
                      {t("Staking.banner.stakeMore")}
                    </button>
                    <a
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      href="/dashboard"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                      {t("Staking.banner.manageStaking")}
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("Staking.banner.manageUpdatedBalance")}</i>
                  </div>
                </div>
              </div>{" "}
            </div>
          </ModalPortal>
        </>
      )}

      {/* ========================== COMPOUND SUMMARY MODAL ========================== */}
      {compoundSummary && (
        <>
          {" "}
          <ModalPortal>
            <div>
              <div className="modl-over-staking"></div>
              <div
                className="heo-staking"
                style={{
                  // position: "absolute",
                  // top: `${modalTop}px`,
                  position: "fixed",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "9999999",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img src={purchaseSummary} className="purchase-summary" />
                      {t("Staking.banner.compoundSummary")}
                    </h2>
                    <div className="cross-ic">
                      <svg
                        onClick={() => setcompoundSummary(false)}
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={claimSuccess}
                      className="purchase-success"
                      alt={t("Staking.banner.compoundSuccess")}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("Staking.banner.congratulations")} <br />{" "}
                      {t("Staking.banner.compoundingSuccessful")}
                    </h2>
                  </div>

                  {/* If you just compounded claimableRewards, show that */}
                  <h3 className="text-center double-blk bjpar your-tokens-bg">
                    <div>
                      <img src={snovaCoin} className="purchased-snova" />
                      <span className="your-tokens">
                        {fmt(previouslyClaimedAmount, 4)} SNOVA
                      </span>
                    </div>
                    <div>
                      <span className="your-tokens  smaller-ft">
                        = ${fmt(previouslyClaimedAmount * price, 2)}
                      </span>
                    </div>
                  </h3>

                  <table className="table-cl">
                    <tbody>
                      <tr style={{ borderBottom: "1px solid #fc6432" }}>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-header no-tp nobot column-dark"
                        >
                          {t("Staking.banner.yourSnovaHoldings")}
                        </td>
                        <td
                          className="table-cell no-tp column-light"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "none",
                          }}
                        >
                          {fmt(totalSnovaHoldings, 4)} SNOVA
                          <span role="img" style={{ marginLeft: 5 }}>
                            <Tooltip
                              text={t(
                                "Staking.banner.updatedSnovaHoldingsTooltip"
                              )}
                            >
                              <img
                                src={totalRewards}
                                style={{ height: "18px" }}
                                alt="Total Rewards"
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header no-tp nmop column-light">
                          {t("Staking.banner.yourStakedSnova")}
                        </td>
                        <td
                          className="table-cell no-tp flex items-centers column-dark"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "none",
                          }}
                        >
                          {fmt(stakedSnovaBalance, 4)} SNOVA
                          <span role="img" style={{ marginLeft: 5 }}>
                            <Tooltip
                              text={t(
                                "Staking.banner.updatedStakedSnovaTooltip"
                              )}
                            >
                              <img
                                src={totalRewards}
                                style={{ height: "18px" }}
                                alt="Total Rewards"
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="flex main-gb">
                    <button
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold btn-scale"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                      onClick={() => setcompoundSummary(false)}
                    >
                      {t("Staking.banner.stakeMore")}
                    </button>
                    <a
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      href="/dashboard"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                      {t("Staking.banner.manageStaking")}
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("Staking.banner.compoundDaily")}</i>
                  </div>
                  <div className="invite-card moti">
                    <h1 className="invite-title primary-color">
                      <img src={referralCoins} className="referral-coins" />{" "}
                      {t("Staking.banner.earn20")}
                    </h1>
                    <p className="invite-text">
                      {t("Staking.banner.inviteFriends")}
                    </p>
                    <div className="invite-link-container">
                      <div className="invite-link">
                        <span>{formatReferralLink(referralLink)}</span>
                        <button className="copy-button">
                          {copied ? (
                            <div className="_copyBtn_1fjrj_20 we">
                              <img src={done} alt="Copy" />
                            </div>
                          ) : (
                            <div
                              className="_copyBtn_1fjrj_20 we"
                              onClick={() => handleCopy(referralLink)}
                            >
                              <img src={CopyIco} alt="Copy" />
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </ModalPortal>
        </>
      )}

      {/* ========================== CLAIM SUMMARY MODAL ========================== */}
      {claimSummary && (
        <>
          {" "}
          <ModalPortal>
            <div>
              <div className="modl-over-staking"></div>
              <div
                className="heo-staking"
                style={{
                  // position: "absolute",
                  // top: `${modalTop}px`,
                  position: "fixed",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "9999999",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img src={purchaseSummary} className="purchase-summary" />
                      {t("Staking.banner.claimSummary")}
                    </h2>
                    <div className="cross-ic">
                      <svg
                        onClick={() => setclaimSummary(false)}
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={claimSuccess}
                      className="purchase-success"
                      alt={t("Staking.banner.claimSuccess")}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("Staking.banner.congratulations")} <br />{" "}
                      {t("Staking.banner.claimSuccessful")}
                    </h2>
                  </div>

                  {/* Claimed claimableRewards */}
                  <h3 className="text-center double-blk bjpar your-tokens-bg">
                    <div>
                      <img src={snovaCoin} className="purchased-snova" />
                      <span className="your-tokens">
                        {fmt(previouslyClaimedAmount, 4)} SNOVA
                      </span>
                    </div>
                    <div>
                      <span className="your-tokens smaller-ft">
                        = ${fmt(previouslyClaimedAmount * price, 2)}
                      </span>
                    </div>
                  </h3>

                  <table className="table-cl">
                    <tbody>
                      <tr style={{ borderBottom: "1px solid #fc6432" }}>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-header no-tp nobot column-dark"
                        >
                          {t("Staking.banner.yourSnovaHoldings")}
                        </td>
                        <td
                          className="table-cell flex no-tp column-light"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "none",
                          }}
                        >
                          {fmt(totalSnovaHoldings, 4)} SNOVA
                          <span
                            role="img"
                            className="cursor-pointer pointer"
                            style={{ marginLeft: 5 }}
                          >
                            <Tooltip
                              text={t(
                                "Staking.banner.updatedSnovaHoldingsTooltip"
                              )}
                            >
                              <img
                                src={totalRewards}
                                style={{ height: "18px" }}
                                alt="Total Rewards"
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header no-tp nmop column-light">
                          {t("Staking.banner.yourStakedSnova")}
                        </td>
                        <td
                          className="table-cell no-tp items-centers column-dark"
                          style={{ alignItems: "center" }}
                        >
                          {fmt(stakedSnovaBalance, 4)} SNOVA
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header nmop column-dark">
                          {t("Staking.banner.yourUnstakedSnova")}
                        </td>
                        <td
                          className="table-cell no-tp flex items-centers column-light"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "none",
                          }}
                        >
                          {fmt(unstakedSnova, 4)} SNOVA
                          <span
                            role="img"
                            className="cursor-pointer pointer"
                            style={{ marginLeft: 5 }}
                          >
                            <Tooltip
                              text={t("Staking.banner.unstakedSnovaTooltip")}
                            >
                              <img
                                src={totalRewards}
                                style={{ height: "18px" }}
                                alt={t("Staking.banner.totalRewards")}
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="flex main-gb">
                    <button
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold btn-scale"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                      onClick={() => setclaimSummary(false)}
                    >
                      {t("Staking.banner.stakeMore")}
                    </button>
                    <a
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      href="/dashboard"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                      {t("Staking.banner.manageStaking")}
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("Staking.banner.claimedRewardsNote")}</i>
                  </div>
                </div>
              </div>{" "}
            </div>
          </ModalPortal>
        </>
      )}
    </div>
  );
};

export default Banner;
